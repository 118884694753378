<template>
  <v-card flat outline class="checkout-summary pa-3 pb-2 mb-3 rounded-md">
    <div class="mb-3 text-body-1 font-weight-semibold text-uppercase">
      {{ $t(title) }}
    </div>
    <v-card-text class="summary-text pa-0">
      <div
        class="d-flex justify-space-between align-center font-weight-semibold mb-1"
      >
        <span>
          {{
            delivered
              ? $t("cartSummary.totalPriceCheckoutDelivered")
              : $t("cartSummary.totalPriceCheckout")
          }}
        </span>
        <span>
          {{
            $n(orderCart.deliveredNetTotal || orderCart.totalPrice, "currency")
          }}
        </span>
      </div>

      <!-- totalPriceVariable -->
      <div
        class="d-flex justify-space-between align-center text-body-2 mb-1"
        v-if="orderCart.totalPriceVariable > 0 && !delivered"
      >
        <span>{{ $t("checkout.summary.itemsTotalByWeight") }}</span>
        <span>{{ $n(orderCart.totalPriceVariable, "currency") }}</span>
      </div>
      <div
        class="d-flex justify-space-between align-center text-body-2  mb-1"
        v-if="orderCart.shippingTotal > 0"
      >
        <span>{{ $t("checkout.summary.shippingTotal") }}</span>
        <span>{{ $n(orderCart.shippingTotal, "currency") }}</span>
      </div>
      <div
        v-if="saved != 0 && !delivered"
        class="d-flex text-uppercase justify-space-between align-top font-weight-bold"
      >
        <span>{{ $t("checkout.summary.saving") }}</span>
        <span> -{{ $n(saved, "currency") }} </span>
      </div>
      <div
        class="d-flex justify-space-between align-center text-body-2  mb-1"
        v-if="orderCart.deliveryFee > 0"
      >
        <span>{{ deliveryFeeTitle }}</span>
        <span>{{ $n(orderCart.deliveryFee, "currency") }}</span>
      </div>

      <div
        class="d-flex justify-space-between align-center text-body-2  mb-1"
        v-for="(orderPackage, index) in orderPackages"
        :key="index + '_package'"
      >
        <span
          >{{ orderPackage.package.descr }} <br />({{
            orderPackage.quantity
          }}&nbsp;x&nbsp;{{ $n(orderPackage.package.cost, "currency") }})</span
        >
        <span>{{ $n(orderPackage.grossTotal, "currency") }}</span>
      </div>
      <div
        class="d-flex justify-space-between align-center text-body-2 font-weight-semibold mb-1 mt-4"
        v-if="orderCart.packageTotal > 0 && !delivered"
      >
        <span>{{ $t("checkout.summary.packageTotal") }}</span>
        <span>{{ $n(orderCart.packageTotal, "currency") }}</span>
      </div>

      <div
        class="gift-certificates-to-generate d-flex flex-nowrap justify-space-between align-center text-body-2 mt-4"
        v-for="(gift, index) in orderCart.giftCertificatesToGenerate"
        :key="index"
      >
        <span class="mr-8">{{ gift.description }}</span>
        <span v-if="gift.amount > 0 || gift.total > 0">
          <!-- {{
            gift.description.indexOf("%") > -1
              ? gift.amount + "&nbsp;%"
              : $n(gift.total, "currency")
          }} -->
          {{
            !(gift.amount && gift.amount >= 0) && gift.total > 0
              ? $n(gift.total, "currency")
              : ""
          }}
        </span>
      </div>

      <div
        v-for="(gift, index) in orderCart.giftCertificates"
        :key="index + '_gift'"
      >
        <div
          class="gift-certificates d-flex flex-nowrap justify-space-between align-center text-body-2 mt-4"
          v-if="gift.amount > 0 && !(gift.total > 0)"
        >
          <span class="mr-8">{{ gift.description }}</span>

          <span
            v-if="(gift.amount > 0 || gift.total > 0) && canDeleteGift"
            class="d-flex flex-nowrap align-center"
            >{{
              gift.description.indexOf("%") > -1
                ? gift.amount + "&nbsp;%"
                : $n(gift.amount, "currency")
            }}
            <v-tooltip v-if="canDeleteGift" top max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  @click="removeGiftCodeV2(gift.userGiftCertificateId)"
                  ><v-icon color="primary" small>
                    $clear
                  </v-icon></v-btn
                >
              </template>
              <span>{{ $t("checkout.giftCodeRemoveTooltip") }}</span>
            </v-tooltip>
          </span>
        </div>
        <div
          class="d-flex flex-nowrap justify-space-between align-center text-body-2 w-100 mt-4"
          v-else-if="gift.total > 0"
        >
          <span class="mr-8">{{ gift.description }}</span>
          <span
            v-if="(gift.amount > 0 || gift.total > 0) && canDeleteGift"
            class="d-flex flex-nowrap align-center"
            >{{ gift.total > 0 ? $n(gift.total, "currency") : "" }}
            <v-tooltip v-if="canDeleteGift" top max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  @click="removeGiftCodeV2(gift.userGiftCertificateId)"
                  class="remove-gift"
                  ><v-icon color="primary" small>
                    $clear
                  </v-icon></v-btn
                >
              </template>
              <span>{{ $t("checkout.giftCodeRemoveTooltip") }}</span>
            </v-tooltip>
          </span>
        </div>
      </div>
      <div v-for="(voucher, index) in filteredVouchers" :key="index + '_vll'">
        <div
          class="gift-certificates vll-gift-voucher d-flex flex-nowrap justify-space-between align-center text-body-2 mt-4"
        >
          <span class="mr-8">{{ voucherName(voucher) }}</span>
          <span class="d-flex flex-nowrap align-center"
            ><span v-if="voucher.advantage">{{
              $n(voucher.advantage, "currency")
            }}</span>
            <v-tooltip v-if="canDeleteGift" top max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  @click="removeGiftCodeV2(voucher.ean)"
                  ><v-icon color="primary" small>
                    $clear
                  </v-icon></v-btn
                >
              </template>
              <span>{{ $t("checkout.giftCodeRemoveTooltip") }}</span>
            </v-tooltip>
          </span>
        </div>
      </div>

      <div
        class="d-flex justify-space-between align-center text-body-2 mb-1 font-weight-semibold mt-2"
        v-if="orderCart.giftTotal > 0"
      >
        <span>{{ $t("checkout.summary.giftTotal") }}</span>
        <span>{{ $n(orderCart.giftTotal, "currency") }}</span>
      </div>

      <div
        class="d-flex justify-space-between align-center text-body-2  mb-1"
        v-if="orderCart.refundTotal > 0"
      >
        <span>{{ $t("checkout.summary.refundTotal") }}</span>
        <span>{{ $n(orderCart.refundTotal, "currency") }}</span>
      </div>
      <div
        v-if="showEarnedCredit && earnedCredit && earnedCredit > 0"
        class="earned-credit"
        v-html="
          $t('checkout.summary.vault.earned', [$n(earnedCredit, 'currency')])
        "
      ></div>

      <v-divider class="my-3"></v-divider>

      <!-- <div
        class="d-flex justify-space-between align-center text-body-2  mb-1"
        v-if="
          orderCart.userPoints &&
            orderCart.userPoints['98000002'] &&
            orderCart.userPoints['98000002'].value > 0 &&
            orderCart.user.fidelityCard
        "
      >
        <span class="font-weight-semibold">{{
          $t("checkout.summary.accumulate")
        }}</span>
        <span>{{ orderCart.userPoints["98000002"].value }} punti</span>
      </div> -->

      <!-- <div
        class="d-flex justify-space-between align-center text-body-2  mb-1"
        v-if="
          orderCart.userPoints &&
            orderCart.userPoints['90000528'] &&
            orderCart.userPoints['90000528'].value > 0
        "
      >
        <span>{{ $t("checkout.summary.cashback") }}</span>
        <span>{{
          $n(orderCart.userPoints["90000528"].value / 100, "currency")
        }}</span>
      </div> -->

      <!-- <div
        class="d-flex justify-space-between align-center text-body-2  mb-1"
        v-if="
          orderCart.userPoints &&
            orderCart.userPoints['86000051'] &&
            orderCart.userPoints['86000051'].value > 0
        "
      >
        <span>{{ $t("checkout.summary.cashbackToSpend") }}</span>
        <span>{{
          $n(orderCart.userPoints["86000051"].value / 100, "currency")
        }}</span>
      </div> -->

      <!-- <div
        v-if="
          orderCart.userPoints &&
            orderCart.userPoints['BOLL'] &&
            orderCart.userPoints['BOLL'].value > 0
        "
        class="d-flex justify-space-between align-center text-body-2 mt-4"
      >
        <span class="font-weight-semibold">{{ $t("checkout.summary.earn") }}</span>
        <span
          >{{ orderCart.userPoints["BOLL"].value }}&nbsp;
          {{ $t("checkout.summary.bollini") }}
        </span>
      </div> -->

      <div
        class="collezionamento-title font-weight-semibold mt-4"
        v-if="filteredOrderPackages && filteredOrderPackages.length > 0"
      >
        {{ $t("checkout.summary.sheets") }}
      </div>
      <div
        class="d-flex justify-space-between align-center text-body-2 mt-4 pb-1"
        v-for="(orderPackage, index) in filteredOrderPackages"
        :key="index - '_orderPackage'"
      >
        <span>{{ orderPackage.package.descr }}</span>
        <span>{{ orderPackage.quantity }}</span>
      </div>

      <div class="d-flex justify-space-between align-center mt-4">
        <span class="text-h4">{{
          delivered
            ? $t("checkout.summary.totalDelivered")
            : $t("checkout.summary.total")
        }}</span>
        <span class="text-h3">
          <OrderGrossTotal
            :item="orderCart"
            :delivered="delivered"
          ></OrderGrossTotal>
        </span>
      </div>
    </v-card-text>
  </v-card>
</template>
<style lang="scss">
.earned-credit {
  background: #ffc867;
  border-radius: 6px;
  padding: 12px;
}
</style>
<script>
import OrderGrossTotal from "@/components/orders/OrderGrossTotal";
import { mapState, mapActions } from "vuex";
import customService from "@/service/customService";

export default {
  name: "CheckoutSummary",
  components: { OrderGrossTotal },
  props: {
    orderCart: { type: Object, required: true },
    delivered: { type: Boolean, default: false },
    title: { type: String, default: "checkout.summary.cart" },
    canDeleteGift: { type: Boolean, default: true },
    showEarnedCredit: { type: Boolean, default: false, required: false }
  },
  data() {
    return {
      earnedCredit: 0,
      filteredVouchers: null
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    saved() {
      return (
        this.orderCart.orderAdjustmentSummary?.TYPE_ITEM_TOTAL.totalVariation +
        this.orderCart.orderAdjustmentSummary?.TYPE_NET_TOTAL.totalVariation
      );
      // return this.orderCart.giftTotal + this.orderCart.totalDiscount;
    },
    deliveryFeeTitle() {
      return this.$t(
        "checkout.summary.deliveryFeeTitle." +
          this.orderCart.shippingAddress.addressType
      );
    },
    orderPackages() {
      // real sacchetti
      return this.orderCart.orderPackages
        ? this.orderCart.orderPackages.filter(
            item => item.package.packageTypeId !== 30
          )
        : [];
    },
    filteredOrderPackages() {
      // used for collezionamento/scarico punti
      return this.orderCart.orderPackages
        ? this.orderCart.orderPackages.filter(
            orderPackage => orderPackage.package.packageTypeId === 30
          )
        : [];
    }
  },
  methods: {
    ...mapActions({
      getCart: "cart/getCart",
      loadCart: "cart/loadCart"
    }),
    voucherName(voucher) {
      let name = voucher.ean;
      if (voucher.messages && voucher.messages.length > 0) {
        name = voucher.messages[0];
      }
      return name;
    },
    async filterVouchers() {
      // await this.loadCart();
      if (this.cart.cartInfos?.VLL_GIFT_VOUCHER) {
        const vll_gift_voucher = JSON.parse(
          this.cart.cartInfos.VLL_GIFT_VOUCHER
        );
        const vouchers = vll_gift_voucher.codes.filter(item => {
          const today = this.$dayjs();

          // const hasValidAdvantage = item.advantage && item.advantage > 0;
          const hasValidAdvantage = true;
          const filterByDate = global.config.filterVouchersByDate;

          const hasValidStartDate =
            !filterByDate ||
            !item.start_date ||
            this.$dayjs(item.start_date, "YYYYMMDDHHmm").isBefore(today) ||
            this.$dayjs(item.start_date, "YYYYMMDDHHmm").isSame(today, "day");

          const hasValidEndDate =
            !filterByDate ||
            !item.end_date ||
            this.$dayjs(item.end_date, "YYYYMMDDHHmm").isAfter(today) ||
            this.$dayjs(item.end_date, "YYYYMMDDHHmm").isSame(today, "day");

          return hasValidAdvantage && hasValidStartDate && hasValidEndDate;
        });
        this.filteredVouchers = vouchers;
      }
    },
    async removeGiftCode(giftCodeId) {
      this.$emit("removeGiftCode", giftCodeId);
      await this.getCart();
      await this.filterVouchers();
    },
    async removeGiftCodeV2(giftCodeId) {
      await customService.customRemoveCode(giftCodeId);
      await this.getCart();
      await this.filterVouchers();
      // this.$emit("removeGiftCode", giftCodeId);
    }
  },
  async mounted() {
    // const cart = await this.getCart();
    if (this.cart.cartInfos?.EARNED_CREDIT) {
      this.earnedCredit = Number(this.cart.cartInfos.EARNED_CREDIT);
    }
    await this.filterVouchers();
    global.EventBus.$on("reloadVllGiftVouchers", await this.filterVouchers());
  }
};
</script>
